<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <h2>{{ overviewTitle }}</h2>
      <p>{{ description }}</p>
      <p>
        Please see below Data Types Accepted by TAIR and Other Repositories (for
        data types not accepted by TAIR).
      </p>
      <b>DATA TYPES ACCEPTED BY TAIR</b>
      <dl class="text-left">
        <dd>
          Please take a look at the specific guidelines below for submitting
          data to TAIR. You will be asked to download an Excel worksheet to fill
          in and submit to TAIR. If you have any questions or suggestions,
          please contact us at:
          <a
            href="javascript:location.href='mailto:curator@arabidopsis.org?SUBJECT=' + document.title + '&amp;BODY=' +'URL: ' + escape(location.href)"
            >curator@arabidopsis.org</a
          >
        </dd>

        <dt>
          <b>External Links</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-ExternalLinks"
            target="_blank"
            >Guidelines</a
          >
          -
          <a
            href="https://tair-data.s3.us-west-2.amazonaws.com/download-forms/external_link_data_form.xls"
            target="_blank"
            >Submission Form</a
          >
        </dt>
        <dd>
          We provide links from TAIR detail pages to other stable and generally
          useful data resources.
        </dd>

        <dt>
          <b>Gene Class Symbol Registration</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneClassSymbolRegistration"
            target="_blank"
            >Guidelines</a
          >
          -
          <a href="/submit/gene_class_symbol_registration" target="_blank"
            >Online Form</a
          >
        </dt>
        <dd>
          Reserve a gene symbol prior to publication to ensure uniqueness and
          consistency in gene nomenclature.
        </dd>

        <!-- <dt>
          <b>Gene Family Data</b> -
          <a href="/submit/genefamily_submission.jsp">Guidelines</a> -
          <a href="/download_files/Data_Submission/gene_family_data_form.xls"
            >Submission Form</a
          >
        </dt>
        <dd>
          For inclusion on the TAIR
          <a href="/browse/genefamily/index.jsp" target="display"
            >Arabidopsis Gene Family</a
          >
          page.
        </dd> -->

        <dt>
          <b>Gene Function Submission Forms for Authors and Others</b> -
          <a href="https://goat.phoenixbioinformatics.org/">Online Form</a> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneFunctionData"
            >Large Dataset Submission Form</a
          >
        </dt>
        <dd>
          Please submit your summary level data regarding all aspects of gene
          function, including gene product function, biological process,
          subcellular localization, expression pattern, and interactions with
          other gene products. See Other Repositories below for submission of
          quantitative experimental results.
        </dd>

        <dt>
          <b>Gene Structure Additions/Modifications</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneStructureAdditions/Modifications"
            >Guidelines</a
          >
          -
          <a
            href="https://tair-data.s3.us-west-2.amazonaws.com/download-forms/2023_structural_annotation_data_form.xlsx"
            >Submission Form</a
          >
        </dt>
        <dd>
          If you have found missing information, errors or discrepancies in the
          structure of an existing gene in TAIR, we would like to update our
          gene information to include your data.
        </dd>

        <dt><b>JBrowse tracks</b></dt>
        <dd>
          We accept a variety of data in
          <a
            href="https://github.com/The-Sequence-Ontology/Specifications/blob/master/gff3.md"
          >
            GFF3 format</a
          >
          that can be displayed as JBrowse tracks. We can also provide a private
          space for peer review. Please contact
          <a href="mailto:curator@arabidopsis.org">TAIR curators</a> about your
          specific data types.
        </dd>

        <dt>
          <b>Marker and Polymorphism Data</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-MarkerandPolymorphismData"
            >Guidelines</a
          >
          -
          <a
            href="https://tair-data.s3.us-west-2.amazonaws.com/download-forms/marker_polymorphism_data_form.xls"
            >Submission Form</a
          >
        </dt>
        <dd>
          Submit data on sequenced alleles and markers (including CAPS, SSLP,
          AFLP, RFLP and RAPD) for inclusion in TAIR.
        </dd>

        <dt>
          <b>Phenotypes</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Phenotypes"
            >Guidelines</a
          >
          -
          <a
            href="https://tair-data.s3.us-west-2.amazonaws.com/download-forms/phenotype_data_form.xls"
            >Submission Form</a
          >
        </dt>
        <dd>
          Submit phenotype descriptions for plants that you have characterized,
          whether they are your own stocks or were obtained from a stock center
          like ABRC.
        </dd>

        <dt>
          <b>Protocols</b> -
          <a
            href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Protocols"
            >Guidelines</a
          >
        </dt>
        <dd>
          Make your protocol available through the
          <a href="/search/protocol">TAIR Protocol Search.</a>
        </dd>

        <!-- <dt><b>[DEPRECATED]2010 Functional Genomics Gene List</b></dt>
        <dd>
          Submitted lists are displayed in the
          <a href="/portals/masc/projects.jsp" target="display"
            >2010 Projects</a
          >
          section.
        </dd> -->
      </dl>
      <b>OTHER REPOSITORIES</b>
      <dl class="text-left">
        <dt>
          Arabidopsis Seeds and DNA stocks-
          <a href="https://abrc.osu.edu/researchers">ABRC</a>
        </dt>
        <dd>
          For seed stocks, clones, vectors, libraries, host strains and other
          similar resources of potential interest to the community.
        </dd>

        <dt>Arabidopsis GWAS data</dt>
        <dd>
          Please submit Genome Wide Association Study data to
          <a href="https://aragwas.1001genomes.org">AraGWAS</a>
        </dd>

        <dt>Arabidopsis Phenotypes</dt>
        <dd>
          TAIR accepts data for individual mutant phenotypes (see above). High
          throughput phenotype data can be submitted to
          <a href="https://arapheno.1001genomes.org/">AraPheno</a>
        </dd>

        <dt>Expression data</dt>
        <dd>
          Please submit high throughput gene expression data to
          <a href="http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?db=gds">GEO</a>
          or <a href="http://www.ebi.ac.uk/arrayexpress/">ArrayExpress</a>
        </dd>

        <dt>Metabolic Pathway Data</dt>
        <dd>
          Please submit plant metabolic pathway data either the
          <a href="https://plantcyc.org/">Plant Metabolic Network</a> or
          <a href="http://plantreactome.gramene.org/">Plant Reactome </a>
        </dd>

        <dt>Nucleotide and Protein Sequences</dt>
        <dd>
          Please submit cDNA, EST, genomic clone and protein sequence data to
          <a href="http://www.ncbi.nlm.nih.gov/Genbank/submit.html">GenBank</a>,
          <a href="http://www.ebi.ac.uk/embl/Submission/index.html">EMBL</a>,
          <a href="https://ddbj.nig.ac.jp/D-way/">DDBJ</a>,
          <a href="http://www.ebi.ac.uk/swissprot/Submissions/spin/">UniProt</a>
        </dd>

        <dt>Protein interaction data</dt>
        <dd>
          Please submit protein interaction data to
          <a href="http://www.ebi.ac.uk/intact/site/">IntAct</a>
        </dd>

        <dt>Protein structure data</dt>
        <dd>
          Please submit protein structures to
          <a href="http://www.wwpdb.org/deposition/faq">PDB</a>
        </dd>

        <dt>
          Proteomics data sets (e.g. mass spec, post translational
          modifications)
        </dt>
        <dd>
          Please submit proteomic datasets to the appropriate
          <a href="https://www.proteomexchange.org/">Proteome Exchange</a>
          member resource
        </dd>

        <dt>Sequencing Data (high throughput)</dt>
        <dd>
          Please submit next generation sequencing data to the
          <a href="https://www.ncbi.nlm.nih.gov/sra"
            >Sequence Read Archive (SRA)</a
          >
        </dd>

        <dt>Variant Datasets (e.g. SNPs)</dt>
        <dd>
          Large scale variant datasets can be submitted to to
          <a href="https://www.ebi.ac.uk/eva/">European Variant Archive </a>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmissionOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Data Submission",
          active: true,
        },
      ],
      overviewTitle: "Data Submission Overview",
      description:
        "We encourage users of TAIR to share their data with the research community.",
      searchOptions: [
        {
          name: "ABRC Stock Donation",
          route: "https://abrc.osu.edu/",
        },
        {
          name: "External Links",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-ExternalLinks",
        },
        {
          name: "Gene Class Symbol Registration",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneClassSymbolRegistration",
        },
        {
          name: "Gene Function Submission Forms for Authors and Others",
          route: "https://goat.phoenixbioinformatics.org",
        },
        {
          name: "Gene Structure Additions/ Modifications",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneStructureAdditions/Modifications",
        },
        {
          name: "Marker and Polymorphism Data",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-MarkerandPolymorphismData",
        },
        {
          name: "Phenotypes",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Phenotypes",
        },
        {
          name: "Protocols",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Protocols",
        },
      ],
    };
  },
  mounted() {
    document.title = "Data Submission Overview";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.side-wrapper {
  width: 30%;
}
</style>
